<template>
  <div class="home">
    <HeadMobile></HeadMobile>
    <div class="home-body">
      <div class="customized-box">
        <div class="customized-titles">
          <p class="title">
            {{ $store.state.staticName.home_title_1 }}
          </p>
          <p class="subhead">
            {{ $store.state.staticName.home_subhead_1 }}
          </p>
        </div>
        <div class="customized-list">
          <div
            class="customized-item"
            v-for="(item, i) in CustomizedData"
            :key="'cus' + i"
            @click="goTitaniumProducts(item.id)"
          >
            <div class="img" v-if="item.abbreviation">
              <img
                :src="item.abbreviation[0]"
                style="width: 100%; height: 100%"
              />
            </div>
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="product-box">
        <div class="product-title">
          {{ $store.state.staticName.home_title_2 }}
        </div>
        <div class="product-subhead">
          {{ $store.state.staticName.home_subhead_2 }}
        </div>
        <div class="product-list">
          <div
            class="product-item"
            v-for="(item, i) in prcData"
            :key="'pro' + i"
            @click="toProduct(item.id, item.tradeCategory)"
          >
            <div class="product-item-text">
              {{ item.tradeName }}
            </div>
            <div class="product-item-img" v-if="item.tradeBanner">
              <img
                :src="item.tradeBanner[0]"
                style="width: 100%; height: 100%; border-radius: 5px"
              />
            </div>
          </div>
        </div>
        <div class="read-more" @click="handleClick">
          &nbsp;&nbsp;{{ $store.state.staticName.read_more }}&nbsp;&nbsp;
        </div>
      </div>
      <div class="news-box">
        <div class="news-title-list">
          <div
            class="news-title-item"
            :class="newType === 'ABOUT_US' ? 'active' : ''"
            @click="handleActive('ABOUT_US')"
          >
            {{ $store.state.staticName.home_about_us }}
          </div>
          <div
            class="news-title-item"
            :class="newType === 'INDUSTRY_TRENDS' ? 'active' : ''"
            @click="handleActive('INDUSTRY_TRENDS')"
          >
            {{ $store.state.staticName.home_industry_trends }}
          </div>
          <div
            class="news-title-item"
            :class="newType === 'MEDIA_REPORT' ? 'active' : ''"
            @click="handleActive('MEDIA_REPORT')"
          >
            {{ $store.state.staticName.media_report }}
          </div>
          <div class="read-more" @click="goToNewList">
            &nbsp;&nbsp;{{ $store.state.staticName.read_more }}&nbsp;&nbsp;
          </div>
        </div>
        <div class="news-list">
          <div
            class="new-item-first"
            v-if="firstNew !== {}"
            @click="toNewDetail(firstNew)"
          >
            <div class="img-box" v-if="firstNew.abbreviation">
              <img
                :src="firstNew.abbreviation[0]"
                style="width: 100%; height: 100%"
              />
            </div>
            <div class="new-detail">
              <div class="new-title">
                {{ firstNew.title }}
              </div>
              <div class="new-text">
                {{ firstNew.synopsis }}
              </div>
              <div class="new-date">
                {{ firstNew.time }}
              </div>
            </div>
          </div>
          <div class="new-item-other-list">
            <div
              class="new-item-other-item"
              v-for="(news, index) in newsData"
              :key="'new' + index"
              @click="toNewDetail(news)"
            >
              <div class="new-item-other-title">
                {{ news.title }}
              </div>
              <div class="new-item-other-date">
                {{ news.time }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FootMobile></FootMobile>
  </div>
</template>

<script>
import HeadMobile from "@/components/mobile/HeadMobile";
import FootMobile from "@/components/mobile/FootMobile";

export default {
  name: "HomeMobile",
  components: { FootMobile, HeadMobile },
  data() {
    return {
      active: 0,
      // 定制化服务数据
      CustomizedData: [],
      // 商品数据
      prcData: [],
      // 第一条新闻
      firstNew: {},
      // 新闻数据
      newsData: [],
      // 当前新闻类型
      newType: "ABOUT_US",
      page: {
        type: "CHINESE",
        pageSize: 6,
        currentPage: 1,
      },
    };
  },
  created() {
    this.getCustomizedData();
    this.getPrcData();
    this.getNewsData();
  },
  methods: {
    handleClick() {
      this.page.currentPage = this.page.currentPage + 1;
      this.getPrcData();
    },
    toProduct(id, classify) {
      this.$router.push({
        path: "/productMobile",
        query: {
          c: classify,
          i: id,
        },
      });
    },
    goTitaniumProducts(id) {
      this.$router.push({ path: `/TitaniumProductsMobile/${id}` });
    },
    toNewDetail(data) {
      this.$router.push({
        path: `/newDetailsMobile/${data.type}/${data.id}`,
      });
    },
    goToNewList() {
      this.$router.push({ path: `/newListMobile/${this.newType}` });
    },
    // 获取定制化服务数据
    getCustomizedData() {
      this.$axios({
        url: "/queryCUS_CustomizedFive",
        method: "GET",
        params: {
          type: this.$store.state.languageType,
        },
      }).then((res) => {
        let value = res.data.value;
        if (value.length && value.length === 5) {
          this.CustomizedData = value.slice(0, 4);
        } else {
          this.CustomizedData = value;
        }
      });
    },
    // 获取商品
    getPrcData() {
      (this.page.type = this.$store.state.languageType),
        this.$axios({
          url: "/queryCommodityBySix",
          method: "GET",
          params: this.page,
          //     {
          //   type: this.$store.state.languageType,
          //   pageSize: 6,
          //   currentPage: 1
          // }
        }).then((res) => {
          let value = res.data.value.value;
          if (value !== null && value.length > 0) {
            this.prcData.push(...value);
          } else {
            this.$message.warning(
              this.$store.state.languageType === "CHINESE"
                ? "没有更多数据"
                : "No more data"
            );
          }
          //this.prcData = value;
        });
    },
    getNewsData() {
      this.$axios({
        url: "/queryNewsByList",
        method: "GET",
        params: {
          type: this.$store.state.languageType,
          newType: this.newType,
          pageSize: 6,
          currentPage: 1,
        },
      }).then((res) => {
        let value = res.data.value.value;
        if (value.total > 0) {
          this.firstNew = value.value[0];
          this.newsData = value.value.slice(1);
        } else {
          this.firstNew = {};
          this.newsData = [];
        }
      });
    },
    // 获取当前新闻
    handleActive(newType) {
      this.newType = newType;
      this.getNewsData();
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  .home-body {
    .customized-box {
      width: 375px;

      .customized-titles {
        .title {
          margin-top: 14px;
          width: 375px;
          //height: 20px;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          color: #333333;
        }

        .subhead {
          width: 375px;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          color: #333333;
        }
      }

      .customized-list {
        margin: {
          //left: 10px;
          top: 16px;
          bottom: 26px;
        }
        display: flex;
        //justify-content: center;
        flex-wrap: wrap;
        //grid-template-columns: 180px 180px;
        .customized-item:first-child {
          //margin-left: 0;
        }
        .customized-item {
          position: relative;
          margin-left: 8rem;
          margin-bottom: 7px;

          .img {
            width: 177px;
            height: 134px;
            background: #cecece;
          }

          .text {
            position: absolute;
            top: 48px;
            left: 35px;
            width: 105px;
            height: 20px;
            opacity: 0.4;
            background: #000000;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }

    .product-box {
      width: 375px;
      margin-bottom: 38px;

      .product-title {
        margin-top: 25px;
        width: 375px;
        height: 22px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: #333333;
      }

      .product-subhead {
        width: 375px;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: #333333;
      }

      .product-list {
        margin-top: 14px;
        margin-left: 11px;
        display: grid;
        grid-template-columns: 121px 121px 121px;

        .product-item {
          width: 115px;
          height: 94px;
          background: #f1f1f1;
          border-radius: 5px;
          margin-bottom: 7px;

          .product-item-text {
            width: 115px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 400;
            text-align: left;
            color: #808080;
          }

          .product-item-img {
            width: 115px;
            height: 78px;
            background: #d1d1d1;
            border-radius: 5px;
          }
        }
      }
      .read-more {
        width: 68px;
        margin: 10px auto 0;
        //position: absolute;
        //right: 11px;
        //height: 16px;
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
        font-size: 10px;
        font-weight: 400;
        text-align: center;
        color: #b4b4b4;
        padding: {
          left: 4px;
          right: 4px;
        }
      }
    }

    .news-box {
      margin-bottom: 22px;

      .news-title-list {
        display: flex;
        margin-left: 11px;
        position: relative;
        margin-bottom: 16px;

        .news-title-item {
          height: 24px;
          margin-right: 18px;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          color: #1850af;
        }

        .read-more {
          position: absolute;
          right: 11px;
          height: 16px;
          background: #ffffff;
          border-radius: 8px;
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
          font-size: 10px;
          font-weight: 400;
          text-align: center;
          color: #b4b4b4;
          padding: {
            left: 4px;
            right: 4px;
          }
        }

        .active {
          border-bottom: 1px #1850af solid;
          font-weight: 700;
        }
      }

      .news-list {
        margin-left: 11px;

        .new-item-first {
          display: flex;

          .img-box {
            width: 109px;
            height: 73px;
            background: rgba(0, 0, 0, 0.05);
          }

          .new-detail {
            width: 240px;
            margin-left: 4px;

            .new-title {
              width: 240px;
              font-size: 14px;
              font-weight: 500;
              text-align: left;
              color: #4d4d4d;
              word-break: break-all;
              display: -webkit-box; /**对象作为伸缩盒子模型展示**/
              -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
              -webkit-line-clamp: 1; /**显示的行数**/
              overflow: hidden; /**隐藏超出的内容**/
            }

            .new-text {
              margin-top: 3px;
              width: 240px;
              height: 35px;
              font-size: 11px;
              font-weight: 400;
              text-align: left;
              color: #808080;
              word-break: break-all;
              display: -webkit-box; /**对象作为伸缩盒子模型展示**/
              -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
              -webkit-line-clamp: 2; /**显示的行数**/
              overflow: hidden; /**隐藏超出的内容**/
            }

            .new-date {
              margin-top: 2px;
              width: 240px;
              font-size: 10px;
              font-weight: 400;
              text-align: left;
              color: #333333;
            }
          }
        }

        .new-item-other-list {
          .new-item-other-item {
            margin-top: 17px;
            display: flex;
            margin-right: 11px;
            border-bottom: 1px dashed #999999;

            .new-item-other-title {
              width: 241px;
              height: 18px;
              font-size: 10px;
              font-weight: 400;
              text-align: left;
              color: #4d4d4d;
              word-break: break-all;
              display: -webkit-box; /**对象作为伸缩盒子模型展示**/
              -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
              -webkit-line-clamp: 1; /**显示的行数**/
              overflow: hidden; /**隐藏超出的内容**/
            }

            .new-item-other-date {
              margin-left: 50px;
              width: 83px;
              height: 14px;
              font-size: 10px;
              font-weight: 400;
              text-align: left;
              color: #4d4d4d;
            }
          }
        }
      }
    }
  }
}
</style>
